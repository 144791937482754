.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form {
    margin-top: 40px;
    width: 500px;
}