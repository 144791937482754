.container,
.layout {
  width: 100%;
  height: 100%;
}

.layoutHeader {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.layoutSider {
  color: white;
  background-color: white;
  border-right: 1px solid #ddd;
}

.siderItems {
  margin: 0;
  padding: 0;
}

.siderItem {
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
}

.siderItemActive {
  background-color: #e2f1ff;
}

.layoutContent {
  color: #999;
  background-color: white;
}
