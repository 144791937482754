.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form {
    margin-top: 40px;
    width: 50vw;
}

.separator {
    padding-top: 30px;
    text-align: center;
    font-size: 20px;
}